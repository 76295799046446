import React from "react"
import ContextProvider from "../context"
import classes from "./css/Cirugias.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner"
import Button from "react-bootstrap/Button"
import { Link } from "gatsby"

const ComponentName = () => {
  return (
    <ContextProvider>
      <Layout>
        <SEO title="Mensaje Enviado" />
        <div className={classes.root}>
          <Banner content="Mensaje Enviado" />
          <div className={classes.errorPage}>
            <h3>Su mensaje fue enviado con éxito!</h3>
            <Link to="/">
              <Button variant="outline-secondary">Volver Al Inicio</Button>
            </Link>
          </div>
        </div>
      </Layout>
    </ContextProvider>
  )
}

export default ComponentName
